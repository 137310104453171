.editor-content {
  @apply w-full
  h-full

  text-th-color-neutral-900

  prose-headings:font-inter
  prose-h1:text-xbig
  prose-h2:text-big
  prose-h3:text-xlarge
  prose-h4:text-large


  prose-p:font-inter
  prose-p:p-[0px]
  prose-p:font-normal
  prose-p:text-default

  prose-a:text-blue-700
  prose-a:underline
  prose-a:cursor-pointer

  prose-img:rounded-border-radius-giant
  prose-img:mx-auto

  prose-figure:mx-auto

  prose-ol:px-size-x4
  prose-ol:list-decimal

  prose-ul:px-size-x4
  prose-ul:list-disc

  prose-li:pb-size-x2;
}

.editor-content .ProseMirror {
  outline: none;
  @apply px-size-x3 py-size-x2;
}

.editor-content.tiptap.ProseMirror-focused {
  outline: none;
  border: none;
  box-shadow: none;
}
