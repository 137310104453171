.preview .swiper-slide {
  @apply opacity-40
  cursor-pointer;
}

.preview .swiper-slide-thumb-active {
  @apply opacity-100
  cursor-default;
}

.swiper-button-prev,
.swiper-button-next {
  @apply w-size-x12
  h-size-x12
  bg-white
  rounded-border-radius-small;

  --swiper-navigation-color: #000;
}
