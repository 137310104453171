@import "@aprosoja/styles/globals";

.content {
  @apply w-full

  text-th-color-neutral-900

  prose-headings:font-bold
  prose-headings:font-inter
  prose-h2:text-big


  prose-p:font-inter
  prose-p:p-[0px]
  prose-p:font-normal

  prose-a:text-blue-700
  prose-a:underline
  prose-a:cursor-pointer

  prose-img:rounded-border-radius-giant
  prose-img:mx-auto

  prose-figure:mx-auto

  prose-ol:px-size-x4
  prose-ol:list-decimal

  prose-ul:px-size-x4
  prose-ul:list-disc

  prose-li:pb-size-x2;
}
